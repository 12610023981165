import React from "react";
import { Button } from "antd";
import { useState, useEffect } from "react";
import { Modal, Form, Input, Row, Col } from "antd";
import { Bill } from "../../utils/Bill";
import { BarcodeIdGenerate } from "../../utils/BarcodeIdGenerate";
import { ApiClient } from "../../utils/ApiClient";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import generatePDF from "react-to-pdf";
import ReactToPrint from "react-to-print";

export const SchoolSwimmingClass = () => {
  const targetRef = React.useRef(null);
  const [componentSize, setComponentSize] = useState("large");
  const [reason] = useState("Swimming Pool Classes"); // Default reason
  const [amount, setAmount] = useState(500); // Default amount, but user can change
  const [barcode, setBarcode] = useState(null);
  const [discountValue, setDiscountValue] = useState(0);
  const [customerNames, setCustomerNames] = useState([]); // Store multiple customer names
  const [openPaymentModal, setOpenPaymentModal] = useState(false);
  const [open, setOpen] = useState(false); // Define the state here
  const user = useSelector((state) => state.user.user);
  const [currentCustomerIndex, setCurrentCustomerIndex] = useState(0); // Track the current customer being processed

  const onFormLayoutChange = ({ size }) => {
    setComponentSize("large");
  };

  // Function to generate a new barcode
  const generateBarcode = () => {
    setBarcode(BarcodeIdGenerate());
  };

  const handlePrint = async () => {
    await generatePDF(targetRef, { filename: `${barcode}.pdf` });
  };

  const handlePaymentClose = () => {
    setOpenPaymentModal(false);
    setAmount(500); // Reset the amount to default
    setCustomerNames([]); // Reset the customer names array
    setCurrentCustomerIndex(0); // Reset the index
  };

  // Function to process each customer
  const processCustomer = async (customerName) => {
    try {
      await ApiClient.post("/payment", {
        customerName: customerName,
        amount: amount - discountValue,
        reason: reason, // Use the default reason
        barcode: barcode,
        cashierId: user._id,
        paymentCategory: "Other",
        balance: amount,
        discount: discountValue,
      });
      toast.success(`Payment Completed Successfully! for ${customerName}`);
    } catch (e) {
      toast.error(`Something went wrong for ${customerName}`);
    }
  };

  // Function to go to the next customer
  const handleNextCustomer = async () => {
    const nextIndex = currentCustomerIndex + 1;
    if (nextIndex < customerNames.length) {
      setCurrentCustomerIndex(nextIndex);
      runPaymentProcessForCustomer(customerNames[nextIndex]); // Process the next customer
    } else {
      handlePaymentClose(); // Close the modal when done
    }
  };

  // Main function to run the payment process for each customer
  const runPaymentProcessForCustomer = async (customerName) => {
    generateBarcode(); // Generate new barcode for each customer
    await processCustomer(customerName); // Make the API call for the customer
    handlePrint(); // Print the bill
  };

  const handlePaymentSubmit = async () => {
    const currentCustomerName = customerNames[currentCustomerIndex];
    await runPaymentProcessForCustomer(currentCustomerName); // Process the current customer
  };

  const handleSubmit = () => {
    setOpen(false); // Close the initial modal
    setBarcode(BarcodeIdGenerate());
    setOpenPaymentModal(true); // Open the payment modal
  };

  useEffect(() => {
    setOpen(true);
  }, []);

  return (
    <div className="d-flex justify-content-center my-2 gap-3">
      <Button
        type="primary"
        onClick={() => {
          setOpen(true);
        }}
      >
        Create New Bill
      </Button>

      <Modal
        title="Make Swimming Class Bills"
        centered
        open={open}
        onOk={handleSubmit}
        onCancel={() => {
          setOpen(false);
          setAmount(500); // Reset amount to default
          setCustomerNames([]);
        }}
        okButtonProps={{
          disabled: customerNames.length === 0, // Ensure customer names are provided
        }}
      >
        <div>
          <Row justify="center">
            <Col span={24}>
              <Form
                labelCol={{
                  span: 6,
                }}
                wrapperCol={{
                  span: 18,
                }}
                layout="horizontal"
                initialValues={{
                  size: componentSize,
                }}
                onValuesChange={onFormLayoutChange}
                size={componentSize}
                labelAlign="left"
                style={{ width: "100%" }}
              >
                <Form.Item label="Customer Names">
                  <Input.TextArea
                    style={{ width: "100%" }}
                    value={customerNames.join("\n")}
                    onChange={(e) =>
                      setCustomerNames(e.target.value.split("\n"))
                    } // Split input by new line for multiple customers
                    placeholder="Enter customer names, one per line"
                  />
                </Form.Item>
                <Form.Item label="Amount">
                  <Input
                    type="number"
                    value={amount}
                    onChange={(e) => setAmount(e.target.value)}
                    style={{ width: "100%" }}
                    placeholder="Enter amount"
                  />
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </div>
      </Modal>

      <Modal
        title={`Processing Payment for ${customerNames[currentCustomerIndex] || ""}`}
        open={openPaymentModal}
        onCancel={handlePaymentClose}
        footer={[
          <Button key="back" onClick={handlePaymentClose}>
            Cancel
          </Button>,
          <ReactToPrint
            onBeforePrint={handlePaymentSubmit}
            trigger={() => (
              <Button key="submit" type="primary">
                Print & Next
              </Button>
            )}
            content={() => targetRef.current}
            onAfterPrint={handleNextCustomer} // Go to the next customer after printing
          />,
        ]}
      >
        <Bill
          targetRef={targetRef}
          tempItem={[
            {
              name: reason, // Default reason
              count: 1,
              price: amount, // Allow changing the price, but default to 500
            },
          ]}
          total={amount}
          subTotal={amount}
          balance={amount}
          barcode={barcode}
          customer={customerNames[currentCustomerIndex]} // Show the current customer being processed
          discount={0}
          discountValue={discountValue}
          setDiscountValue={setDiscountValue}
        />
      </Modal>
    </div>
  );
};
